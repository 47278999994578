
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { db, auth } from '../firebaseConfig';
// import { collection, addDoc, query, where, orderBy, onSnapshot, serverTimestamp, doc, updateDoc, increment, getDoc, getDocs } from "firebase/firestore";
// import SignOutButton from './SignOutButton';
// import '../QuestionBoard.css'; 

// const QuestionBoard = () => {
//   const { boardKey } = useParams();
//   const [question, setQuestion] = useState("");
//   const [questions, setQuestions] = useState([]);
//   const [hostEmail, setHostEmail] = useState('');
//   const [currentUserEmail, setCurrentUserEmail] = useState('');
//   const [userUpvotedQuestions, setUserUpvotedQuestions] = useState([]);
//   const [upvoteMessages, setUpvoteMessages] = useState({});

//   useEffect(() => {
//     const fetchQuestionBoard = () => {
//       try {
//         const boardRef = collection(db, 'questionBoards');
//         const boardQuery = query(boardRef, where('key', '==', boardKey));
    
//         getDocs(boardQuery).then(boardSnapshot => {
//           if (!boardSnapshot.empty) {
//             const boardData = boardSnapshot.docs[0].data();
//             setHostEmail(boardData.hostEmail);
//             console.log('Host email is:', boardData.hostEmail);
//           } else {
//             console.log('No question board found for key:', boardKey);
//           }
    
//           const user = auth.currentUser;
//           if (user) {
//             setCurrentUserEmail(user.email);
//             console.log('Current user email:', user.email);
//           } else {
//             console.log('No current user found');
//           }
//         }).catch(error => {
//           console.error('Error fetching question board:', error);
//         });
//       } catch (error) {
//         console.error('Unexpected error:', error);
//       }
//     };
    
//     const fetchBoardDetails = async () => {
//       const boardDoc = await getDoc(doc(db, 'questionBoards', boardKey));
//       if (boardDoc.exists()) {
//         setHostEmail(boardDoc.data().hostEmail);
//       }
//     };

//     fetchBoardDetails();

//     const q = query(
//       collection(db, 'questions'),
//       where('boardKey', '==', boardKey),
//       orderBy('timestamp', 'desc')
//     );

//     const unsubscribe = onSnapshot(q, (querySnapshot) => {
//       const questionsData = [];
//       querySnapshot.forEach((doc) => {
//         questionsData.push({ id: doc.id, ...doc.data() });
//       });
//       setQuestions(questionsData);
//     });

//     const fetchQuestions = async () => {
//       try {
//         const questionRef = collection(db, 'questions');
//         const questionQuery = query(questionRef, where('boardKey', '==', boardKey));
//         const questionSnapshot = await getDocs(questionQuery);
        
//         const questionList = questionSnapshot.docs.map(doc => ({
//           id: doc.id,
//           ...doc.data()
//         }));

//         questionList.sort((a, b) => b.upvotes - a.upvotes);
        
//         setQuestions(questionList);
//         console.log('Questions:', questionList);
//       } catch (error) {
//         console.error('Error fetching questions:', error);
//       }
//     };

//     fetchQuestionBoard();
//     fetchQuestions();
//     return () => unsubscribe();
//   }, [boardKey]);

//   const askQuestion = async () => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to ask a question");
//       return;
//     }

//     console.log("just user", user);
//     console.log("just host", hostEmail);

//     await addDoc(collection(db, 'questions'), {
//       boardKey,
//       question,
//       email: user.email,
//       timestamp: serverTimestamp(),
//       upvotes: 0,
//     });

//     setQuestion("");
//   };

//   const upvoteQuestion = async (id) => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to upvote a question");
//       return;
//     }

//     if (userUpvotedQuestions.includes(id)) {
//       setUpvoteMessages({
//         ...upvoteMessages,
//         [id]: 'You have already upvoted this question'
//       });
//       setTimeout(() => {
//         setUpvoteMessages({
//           ...upvoteMessages,
//           [id]: ''
//         });
//       }, 3000);
//       return;
//     }

//     const questionRef = doc(db, 'questions', id);
//     await updateDoc(questionRef, {
//       upvotes: increment(1)
//     });

//     setUserUpvotedQuestions([...userUpvotedQuestions, id]);
//   };

//   useEffect(() => {
//     console.log("Host Email:", hostEmail);
//   }, [hostEmail]);
  
//   return (
//     <div className="QuestionBoard">
//   <div className="logo">q+a</div>
//   <div className="sign-out-container">
//     <SignOutButton />
//   </div>
//   <div className="room-key">Room Key: {boardKey}</div>
//   <div className="QuestionsList">
//     {questions.map((q) => (
//       <div key={q.id} className="Question">
//         <p>{q.question}</p>
//         {currentUserEmail === hostEmail && <p><small>Asked by: {q.email}</small></p>}
//         <button onClick={() => upvoteQuestion(q.id)}>Upvote</button>
//         <p>Upvotes: {q.upvotes}</p>
//         <p className="upvote-message">{upvoteMessages[q.id]}</p>
//         <hr />
//       </div>
//     ))}
//   </div>
//   <div className="QuestionInput">
//     <textarea
//       value={question}
//       onChange={(e) => setQuestion(e.target.value)}
//       placeholder="Ask a question..."
//     />
//     <button onClick={askQuestion}>Submit</button>
//   </div>
// </div>
//   );
// };

// export default QuestionBoard;


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { db, auth } from '../firebaseConfig';
// import { collection, addDoc, query, where, orderBy, onSnapshot, serverTimestamp, doc, updateDoc, increment, getDoc, getDocs } from "firebase/firestore";
// import SignOutButton from './SignOutButton';
// import '../QuestionBoard.css'; 

// const QuestionBoard = () => {
//   const { boardKey } = useParams();
//   const [question, setQuestion] = useState("");
//   const [questions, setQuestions] = useState([]);
//   const [hostEmail, setHostEmail] = useState('');
//   const [currentUserEmail, setCurrentUserEmail] = useState('');
//   const [userUpvotedQuestions, setUserUpvotedQuestions] = useState([]);
//   const [upvoteMessages, setUpvoteMessages] = useState({});
//   const [hostAnswer, setHostAnswer] = useState("");
//   const [showHostControls, setShowHostControls] = useState({});

//   useEffect(() => {
//     const fetchQuestionBoard = () => {
//       try {
//         const boardRef = collection(db, 'questionBoards');
//         const boardQuery = query(boardRef, where('key', '==', boardKey));
    
//         getDocs(boardQuery).then(boardSnapshot => {
//           if (!boardSnapshot.empty) {
//             const boardData = boardSnapshot.docs[0].data();
//             setHostEmail(boardData.hostEmail);
//             console.log('Host email is:', boardData.hostEmail);
//           } else {
//             console.log('No question board found for key:', boardKey);
//           }
    
//           const user = auth.currentUser;
//           if (user) {
//             setCurrentUserEmail(user.email);
//             console.log('Current user email:', user.email);
//           } else {
//             console.log('No current user found');
//           }
//         }).catch(error => {
//           console.error('Error fetching question board:', error);
//         });
//       } catch (error) {
//         console.error('Unexpected error:', error);
//       }
//     };
    
//     const fetchBoardDetails = async () => {
//       const boardDoc = await getDoc(doc(db, 'questionBoards', boardKey));
//       if (boardDoc.exists()) {
//         setHostEmail(boardDoc.data().hostEmail);
//       }
//     };

//     fetchBoardDetails();

//     const q = query(
//       collection(db, 'questions'),
//       where('boardKey', '==', boardKey),
//       orderBy('timestamp', 'desc')
//     );

//     const unsubscribe = onSnapshot(q, (querySnapshot) => {
//       const questionsData = [];
//       querySnapshot.forEach((doc) => {
//         questionsData.push({ id: doc.id, ...doc.data() });
//       });
//       setQuestions(questionsData);
//     });
//     const fetchQuestions = async () => {
//         try {
//           const questionRef = collection(db, 'questions');
//           const questionQuery = query(questionRef, where('boardKey', '==', boardKey));
//           const questionSnapshot = await getDocs(questionQuery);
          
//           const questionList = questionSnapshot.docs.map(doc => ({
//             id: doc.id,
//             ...doc.data()
//           }));
  
//           questionList.sort((a, b) => b.upvotes - a.upvotes);
          
//           setQuestions(questionList);
//           console.log('Questions:', questionList);
//         } catch (error) {
//           console.error('Error fetching questions:', error);
//         }
//       };
  
//       fetchQuestionBoard();
//       fetchQuestions();
//       return () => unsubscribe();
//     }, [boardKey]);

//   const askQuestion = async () => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to ask a question");
//       return;
//     }

//     await addDoc(collection(db, 'questions'), {
//       boardKey,
//       question,
//       email: user.email,
//       timestamp: serverTimestamp(),
//       upvotes: 0,
//       answered: false,
//       hidden: false,
//       hostAnswer: "",
//     });

//     setQuestion("");
//   };

//   const upvoteQuestion = async (id) => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to upvote a question");
//       return;
//     }

//     if (userUpvotedQuestions.includes(id)) {
//       setUpvoteMessages({
//         ...upvoteMessages,
//         [id]: 'You have already upvoted this question'
//       });
//       setTimeout(() => {
//         setUpvoteMessages({
//           ...upvoteMessages,
//           [id]: ''
//         });
//       }, 3000);
//       return;
//     }

//     const questionRef = doc(db, 'questions', id);
//     await updateDoc(questionRef, {
//       upvotes: increment(1)
//     });

//     setUserUpvotedQuestions([...userUpvotedQuestions, id]);
//   };

//   const toggleAnswered = async (id) => {
//     const questionRef = doc(db, 'questions', id);
//     const questionDoc = await getDoc(questionRef);
//     await updateDoc(questionRef, {
//       answered: !questionDoc.data().answered
//     });
//   };

//   const toggleHidden = async (id) => {
//     const questionRef = doc(db, 'questions', id);
//     const questionDoc = await getDoc(questionRef);
//     await updateDoc(questionRef, {
//       hidden: !questionDoc.data().hidden
//     });
//   };

//   const submitHostAnswer = async (id) => {
//     const questionRef = doc(db, 'questions', id);
    
//     // First, query for the board document
//     const boardQuery = query(collection(db, 'questionBoards'), where('key', '==', boardKey));
//     const boardSnapshot = await getDocs(boardQuery);
    
//     if (boardSnapshot.empty) {
//       console.error('No matching board found');
//       return;
//     }
    
//     const boardDoc = boardSnapshot.docs[0];
//     const boardRef = doc(db, 'questionBoards', boardDoc.id);
    
//     await updateDoc(questionRef, {
//       hostAnswer: hostAnswer,
//       answered: true
//     });
  
//     // Update the lastAnswerTimestamp in the questionBoards collection
//     await updateDoc(boardRef, {
//       lastAnswerTimestamp: serverTimestamp()
//     });
  
//     setHostAnswer("");
//   };

//   const toggleHostControls = (id) => {
//     setShowHostControls(prev => ({
//       ...prev,
//       [id]: !prev[id]
//     }));
//   };

//   return (
//     <div className="QuestionBoard">
//       <div className="logo">q+a</div>
//       <div className="sign-out-container">
//         <SignOutButton />
//       </div>
//       <div className="room-key">Room Key: {boardKey}</div>
//       <div className="QuestionsList">
//         {questions.map((q) => (
//           <div key={q.id} className="Question" style={{ display: q.hidden && currentUserEmail !== hostEmail ? 'none' : 'block' }}>
//             <p>{q.question}</p>
//             {currentUserEmail === hostEmail && <p><small>Asked by: {q.email}</small></p>}
//             <button onClick={() => upvoteQuestion(q.id)}>Upvote</button>
//             <p>Upvotes: {q.upvotes}</p>
//             <p className="upvote-message">{upvoteMessages[q.id]}</p>
//             {q.answered && <span className="answered-tag">Answered</span>}
//             {q.hidden && <span className="hidden-tag">Hidden</span>}
//             {q.hostAnswer && <p className="host-answer">Host Answer: {q.hostAnswer}</p>}
//             {currentUserEmail === hostEmail && (
//               <>
//                 <button onClick={() => toggleHostControls(q.id)}>
//                   {showHostControls[q.id] ? 'Hide Controls' : 'Show Controls'}
//                 </button>
//                 <div className={`host-controls ${showHostControls[q.id] ? 'visible' : ''}`}>
//                   <button onClick={() => toggleAnswered(q.id)}>{q.answered ? 'Mark as Unanswered' : 'Mark as Answered'}</button>
//                   <button onClick={() => toggleHidden(q.id)}>{q.hidden ? 'Unhide' : 'Hide'}</button>
//                   <textarea
//                     value={hostAnswer}
//                     onChange={(e) => setHostAnswer(e.target.value)}
//                     placeholder="Type your answer..."
//                   />
//                   <button onClick={() => submitHostAnswer(q.id)}>Submit Answer</button>
//                 </div>
//               </>
//             )}
//             <hr />
//           </div>
//         ))}
//       </div>
//       <div className="QuestionInput">
//         <textarea
//           value={question}
//           onChange={(e) => setQuestion(e.target.value)}
//           placeholder="Ask a question..."
//         />
//         <button onClick={askQuestion}>Submit</button>
//       </div>
//     </div>
//   );
// };

// export default QuestionBoard;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc, query, where, orderBy, onSnapshot, serverTimestamp, doc, updateDoc, increment, getDoc, getDocs } from "firebase/firestore";
import '../QuestionBoard.css'; 

const QuestionBoard = () => {
  const navigate = useNavigate();
  const { boardKey } = useParams();
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [hostEmail, setHostEmail] = useState('');
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [userUpvotedQuestions, setUserUpvotedQuestions] = useState([]);
  const [upvoteMessages, setUpvoteMessages] = useState({});
  const [hostAnswer, setHostAnswer] = useState("");
  const [showHostControls, setShowHostControls] = useState({});
  const [boardName, setBoardName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);

  const sortQuestionsByUpvotes = (questions) => {
    return [...questions].sort((a, b) => b.upvotes - a.upvotes);
  };

  useEffect(() => {
    const fetchBoardDetails = async () => {
      const boardsRef = collection(db, 'questionBoards');
      const q = query(boardsRef, where('key', '==', boardKey));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const boardDoc = querySnapshot.docs[0];
        const boardData = boardDoc.data();
        setHostEmail(boardData.hostEmail);
        setBoardName(boardData.boardName || "");
        console.log('Host email is:', boardData.hostEmail);
      } else {
        console.log('No question board found for key:', boardKey);
      }
  
      const user = auth.currentUser;
      if (user) {
        setCurrentUserEmail(user.email);
        console.log('Current user email:', user.email);
      } else {
        console.log('No current user found');
      }
    };
  
    fetchBoardDetails();
  
    const q = query(
      collection(db, 'questions'),
      where('boardKey', '==', boardKey),
      orderBy('timestamp', 'desc')
    );
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const questionsData = [];
      querySnapshot.forEach((doc) => {
        questionsData.push({ id: doc.id, ...doc.data() });
      });
      setQuestions(sortQuestionsByUpvotes(questionsData));
    });
  
    return () => unsubscribe();
  }, [boardKey]);

  const updateBoardName = async () => {
    if (currentUserEmail !== hostEmail) return;
  
    try {
      const boardsRef = collection(db, 'questionBoards');
      const q = query(boardsRef, where('key', '==', boardKey));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const boardDoc = querySnapshot.docs[0];
        await updateDoc(doc(db, 'questionBoards', boardDoc.id), {
          boardName: boardName
        });
        console.log('Board name updated successfully');
      } else {
        console.error('No matching board found');
      }
  
      setIsEditingName(false);
    } catch (error) {
      console.error('Error updating board name:', error);
    }
  };

  const handleBoardNameChange = (e) => {
    setBoardName(e.target.value);
  };

  const toggleEditName = () => {
    setIsEditingName(!isEditingName);
  };

  const askQuestion = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert("You must be signed in to ask a question");
      return;
    }

    await addDoc(collection(db, 'questions'), {
      boardKey,
      question,
      email: user.email,
      timestamp: serverTimestamp(),
      upvotes: 0,
      answered: false,
      hidden: false,
      hostAnswer: "",
    });

    setQuestion("");
  };

  const upvoteQuestion = async (id) => {
    const user = auth.currentUser;
    if (!user) {
      alert("You must be signed in to upvote a question");
      return;
    }

    if (userUpvotedQuestions.includes(id)) {
      setUpvoteMessages({
        ...upvoteMessages,
        [id]: 'You have already upvoted this question'
      });
      setTimeout(() => {
        setUpvoteMessages({
          ...upvoteMessages,
          [id]: ''
        });
      }, 3000);
      return;
    }

    const questionRef = doc(db, 'questions', id);
    await updateDoc(questionRef, {
      upvotes: increment(1)
    });

    setUserUpvotedQuestions([...userUpvotedQuestions, id]);
    setQuestions(prevQuestions => sortQuestionsByUpvotes(prevQuestions));
  };

  const toggleAnswered = async (id) => {
    const questionRef = doc(db, 'questions', id);
    const questionDoc = await getDoc(questionRef);
    await updateDoc(questionRef, {
      answered: !questionDoc.data().answered
    });
  };

  const toggleHidden = async (id) => {
    const questionRef = doc(db, 'questions', id);
    const questionDoc = await getDoc(questionRef);
    await updateDoc(questionRef, {
      hidden: !questionDoc.data().hidden
    });
  };

  const submitHostAnswer = async (id) => {
    const questionRef = doc(db, 'questions', id);
    
    const boardQuery = query(collection(db, 'questionBoards'), where('key', '==', boardKey));
    const boardSnapshot = await getDocs(boardQuery);
    
    if (boardSnapshot.empty) {
      console.error('No matching board found');
      return;
    }
    
    const boardDoc = boardSnapshot.docs[0];
    const boardRef = doc(db, 'questionBoards', boardDoc.id);
    
    await updateDoc(questionRef, {
      hostAnswer: hostAnswer,
      answered: true
    });
  
    await updateDoc(boardRef, {
      lastAnswerTimestamp: serverTimestamp()
    });
  
    setHostAnswer("");
  };

  const toggleHostControls = (id) => {
    setShowHostControls(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const goToHome = () => {
    navigate('/main');
  };
  
  

  return (
    <div className="QuestionBoard">
      <div className='header'>
        <div className="board-info">
          <div className="room-key">Room Key: {boardKey}</div>
          {currentUserEmail === hostEmail ? (
            isEditingName ? (
              <div className="board-name-edit">
                <input 
                  type="text" 
                  value={boardName} 
                  onChange={handleBoardNameChange}
                  placeholder="Enter board name"
                />
                <button onClick={updateBoardName}>Save</button>
                <button onClick={toggleEditName}>Cancel</button>
              </div>
            ) : (
              <div className="board-name" onClick={toggleEditName}>
                Board Name: {boardName || "Click to add a name"}
              </div>
            )
          ) : (
            <div className="board-name">
              Board Name: {boardName || "Unnamed Board"}
            </div>
          )}
        </div>
        <div className="logo-container">
          <div className="logo1">q+a</div>
          <button className="home-button" onClick={goToHome}>Home</button>
        </div>
      </div>
      <div className="QuestionsList">
        {questions.map((q) => (
          <div key={q.id} className="Question" style={{ display: q.hidden && currentUserEmail !== hostEmail ? 'none' : 'block' }}>
            <p>{q.question}</p>
            {currentUserEmail === hostEmail && <p><small>Asked by: {q.email}</small></p>}
            <button onClick={() => upvoteQuestion(q.id)}>Upvote</button>
            <p>Upvotes: {q.upvotes}</p>
            <p className="upvote-message">{upvoteMessages[q.id]}</p>
            {q.answered && <span className="answered-tag">Answered</span>}
            {q.hidden && <span className="hidden-tag">Hidden</span>}
            {q.hostAnswer && <p className="host-answer">Host Answer: {q.hostAnswer}</p>}
            {currentUserEmail === hostEmail && (
              <>
                <button onClick={() => toggleHostControls(q.id)}>
                  {showHostControls[q.id] ? 'Hide Controls' : 'Show Controls'}
                </button>
                <div className={`host-controls ${showHostControls[q.id] ? 'visible' : ''}`}>
                  <button onClick={() => toggleAnswered(q.id)}>{q.answered ? 'Mark as Unanswered' : 'Mark as Answered'}</button>
                  <button onClick={() => toggleHidden(q.id)}>{q.hidden ? 'Unhide' : 'Hide'}</button>
                  <textarea
                    value={hostAnswer}
                    onChange={(e) => setHostAnswer(e.target.value)}
                    placeholder="Type your answer..."
                  />
                  <button onClick={() => submitHostAnswer(q.id)}>Submit Answer</button>
                </div>
              </>
            )}
            <hr />
          </div>
        ))}
      </div>
      <div className="QuestionInput">
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Ask a question..."
        />
        <button onClick={askQuestion}>Submit</button>
      </div>
    </div>
  );
};

export default QuestionBoard;