// import React from 'react';
// import styled from 'styled-components';

// const HeaderContainer = styled.header`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 20px;
//   background: transparent;
//   color: #fff;
// `;

// const Logo = styled.div`
//   font-size: 2.0em;
//   font-weight: bold;
// `;

// const Nav = styled.nav`
//   display: flex;
//   gap: 20px;
// `;

// const NavLink = styled.a`
//   color: #fff;
//   text-decoration: none;
//   font-size: 1.1em;
//   position: relative;
//   &:hover {
//     color: #ffe600;
//   }
// `;

// const Header = () => (
//   <HeaderContainer>
//     <Logo>q+a</Logo>
//     {/* <Nav>
//       <NavLink href="#features">Features</NavLink>
//       <NavLink href="#about">About</NavLink>
//       <NavLink href="#contact">Contact</NavLink>
//     </Nav> */}
//   </HeaderContainer>
// );

// export default Header;

import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: transparent;
  color: #fff;
`;

const Logo = styled.div`
  font-size: 2.0em;
  font-weight: bold;
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.1em;
  position: relative;
  &:hover {
    color: #ffe600;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: white;
    color: black;
  }
`;

const Header = () => (
  <HeaderContainer>
    <Logo>q+a</Logo>
    <ButtonContainer>
      <Button onClick={() => window.location.href='/signin'}>Sign In</Button>
      <Button onClick={() => window.location.href='/signup'}>Sign Up</Button>
    </ButtonContainer>
  </HeaderContainer>
);

export default Header;
