

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import styled from 'styled-components';
// import { db, auth } from '../firebaseConfig';
// import { collection, query, where, getDocs } from "firebase/firestore";

// const JoinBoardContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 20px;
// `;

// const JoinBoardRow = styled.div`
//   display: flex;
//   align-items: center;
//   background-color: #495057;
//   border-radius: 300px;
//   padding: 5px 20px;
// `;

// const Text = styled.p`
//   color: white;
//   margin-right: 10px;
// `;

// const Input = styled.input`
//   padding: 10px;
//   margin: 0 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
// `;

// const Button = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 10px;
//   margin-left: 10px;
//   border: none;
//   border-radius: 50%;
//   background-color: white;
//   color: black;
//   cursor: pointer;
//   font-size: 16px;
//   transition: background-color 0.3s, color 0.3s;

//   &:hover {
//     background-color: white;
//   }
// `;

// const ArrowIcon = styled.svg`
//   width: 20px;
//   height: 20px;
//   fill: currentColor;
// `;

// const ErrorMessage = styled.p`
//   color: red;
//   margin-top: 10px;
// `;

// const SignInButton = styled.button`
//   display: flex;
//   align-items: center;
//   padding: 10px;
//   margin-right: 10px;
//   border: none;
//   background-color: transparent;
//   color: white;
//   cursor: pointer;
//   font-size: 16px;
//   transition: color 0.3s;

//   &:hover {
//     background color: white
//     color: black;
//   }
// `;

// const JoinBoard = () => {
//   const navigate = useNavigate();
//   const [joinKey, setJoinKey] = useState("");
//   const [error, setError] = useState("");

//   const joinQuestionBoard = async () => {
//     const user = auth.currentUser;

//     if (!user) {
//       localStorage.setItem('boardKey', joinKey);  // Save board key to localStorage
//       navigate('/signin');
//       return;
//     }

//     const q = query(collection(db, 'questionBoards'), where('key', '==', joinKey));
//     const querySnapshot = await getDocs(q);

//     if (querySnapshot.empty) {
//       setError("This board does not exist");
//     } else {
//       setError("");
//       navigate(`/board/${joinKey}`);
//     }
//   };

//   return (
//     <JoinBoardContainer>
//       <JoinBoardRow>
//         {/* <SignInButton onClick={() => navigate('/signin')}>
//           Sign  / Up
//         </SignInButton> */}
//         <Text>Join a Question Board</Text>
//         <Input 
//           type="text" 
//           placeholder="# Question Board key" 
//           value={joinKey} 
//           onChange={(e) => setJoinKey(e.target.value)} 
//         />
//         <Button onClick={joinQuestionBoard}>
//           <ArrowIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
//             <path d="M8 5l7 7-7 7" />
//           </ArrowIcon>
//         </Button>
//       </JoinBoardRow>
//       {error && <ErrorMessage>{error}</ErrorMessage>}
//     </JoinBoardContainer>
//   );
// };

// export default JoinBoard;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, getDocs } from "firebase/firestore";

const JoinBoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const JoinBoardRow = styled.div`
  display: flex;
  align-items: center;
  background-color: #495057;
  border-radius: 300px;
  padding: 5px 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
    border-radius: 20px;
  }
`;

const Text = styled.p`
  color: white;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
    width: 100%;
  }
`;

const Button = styled.button`

background-color: #495057;
padding: 5px 20px;
border: 0px solid ;


  @media (max-width: 768px) {
    margin-left: 10;
    margin-top: 10px;
    width: 100%;
    border-radius: 4px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const JoinBoard = () => {
  const navigate = useNavigate();
  const [joinKey, setJoinKey] = useState("");
  const [error, setError] = useState("");

  const joinQuestionBoard = async () => {
    const user = auth.currentUser;

    if (!user) {
      localStorage.setItem('boardKey', joinKey);  // Save board key to localStorage
      navigate('/signin');
      return;
    }

    const q = query(collection(db, 'questionBoards'), where('key', '==', joinKey));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      setError("This board does not exist");
    } else {
      setError("");
      navigate(`/board/${joinKey}`);
    }
  };

  return (
    <JoinBoardContainer>
      <JoinBoardRow>
        <Text>Join a Question Board</Text>
        <Input 
          type="text" 
          placeholder="          Question Board key" 
          value={joinKey} 
          onChange={(e) => setJoinKey(e.target.value)} 
        />
        <Button onClick={joinQuestionBoard}>Go</Button>
      </JoinBoardRow>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </JoinBoardContainer>
  );
};

export default JoinBoard;
