

import React, { useState } from 'react';
import styled from 'styled-components';
import '../QuestionBoard.css'; // Ensure this CSS file is correctly imported

const MockQuestionBoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
`;

const MockQuestionBoard = () => {
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState([
    { id: 1, question: "This is the Question Board and shows how user questions are displayed. The room key in the top left is easily accessible and shareable", email: "user1@example.com", upvotes: 5 },
    { id: 2, question: "Questions can be upvoted once by each user and are displayed in descending order (try upvoting this one!)", email: "user2@example.com", upvotes: 5 },
    { id: 3, question: "You are currently in 'Student View' which hides the identities of those asking questions, try 'Host View' to see from the room hosts view", email: "user3@example.com", upvotes: 3 },
    { id: 4, question: "Ask you own question below!", email: "user4@example.com", upvotes: 2 },
  ]);
  const [upvoteMessages, setUpvoteMessages] = useState({});
  const [isHostView, setIsHostView] = useState(false);
  const [upvotedQuestions, setUpvotedQuestions] = useState(new Set()); // Set to track upvoted questions

  const askQuestion = () => {
    const newQuestion = {
      id: questions.length + 1,
      question,
      email: "mockuser@example.com",
      upvotes: 0,
    };
    setQuestions([...questions, newQuestion]);
    setQuestion("");
  };

  const upvoteQuestion = (id) => {
    if (upvotedQuestions.has(id)) {
      setUpvoteMessages({
        ...upvoteMessages,
        [id]: 'You have already upvoted this question'
      });

      setTimeout(() => {
        setUpvoteMessages({
          ...upvoteMessages,
          [id]: ''
        });
      }, 3000); // Remove message after 3 seconds

      return;
    }

    setQuestions(questions.map(q =>
      q.id === id ? { ...q, upvotes: q.upvotes + 1 } : q
    ));

    setUpvotedQuestions(new Set(upvotedQuestions).add(id));
  };

  const toggleView = () => {
    setIsHostView(!isHostView);
  };

  // Sort questions by upvotes in descending order
  const sortedQuestions = [...questions].sort((a, b) => b.upvotes - a.upvotes);

  return (
    <MockQuestionBoardContainer className="QuestionBoard">
      <div className="logo">q+a</div>
      <div className="room-key">Room Key: c3489xbra</div>
      <div className="toggle-view-container">
        <button className="ToggleViewButton" onClick={toggleView}>
          {isHostView ? "Switch to Student View" : "Switch to Host View"}
        </button>
      </div>
      <div className="QuestionsList">
        {sortedQuestions.map((q) => (
          <div key={q.id} className="Question">
            <p>{q.question}</p>
            {isHostView && <p><small>Asked by: {q.email}</small></p>}
            <button onClick={() => upvoteQuestion(q.id)}>Upvote</button>
            <p>Upvotes: {q.upvotes}</p>
            <p className="upvote-message">{upvoteMessages[q.id]}</p>
            <hr />
          </div>
        ))}
      </div>
      <div className="QuestionInput">
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Ask a question..."
        />
        <button onClick={askQuestion}>Submit</button>
      </div>
    </MockQuestionBoardContainer>
  );
};

export default MockQuestionBoard;
