import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from './firebaseConfig';  // Adjust this import path as needed

export const initializeUserDocument = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    // If the document doesn't exist, create it with an empty lastViewedBoards
    await setDoc(userRef, {
      lastViewedBoards: {}
    });
  }
};