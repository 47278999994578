

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import styled, { keyframes } from 'styled-components';
// import JoinBoard from './JoinBoard';

// const HeroContainer = styled.section`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 80vh;
//   background: transparent;
//   color: #fff;
//   text-align: center;
//   padding: 20px;
// `;

// const HeroTitle = styled.h1`
//   font-size: 4em;
//   margin-bottom: 0.5em;
//   line-height: 1.32;
//   margin-left: -300px;
// `;

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

// const fadeOut = keyframes`
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// `;

// const WordContainer = styled.span`
//   display: inline-block;
//   position: relative;
//   height: 1em;
//   vertical-align: baseline;
// `;

// const DynamicWord = styled.span`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   animation: ${props => props.fade ? fadeIn : fadeOut} 1s ease-in-out forwards;
//   opacity: ${props => props.fade ? 1 : 0};
// `;

// const HeroSubtitle = styled.h2`
//   font-size: 2.5em;
//   margin-bottom: 1em;
// `;

// const HeroButton = styled.button`
//   padding: 15px 30px;
//   font-size: 1.5em;
//   color: white;
//   background-color: transparent;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;
//   &:hover {
//     background-color: white;
//   }
// `;

// const words = ['engaging', 'interactive', 'dynamic'];

// const Hero = () => {
//   const [index, setIndex] = useState(0);
//   const [fade, setFade] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setFade(false);
//       setTimeout(() => {
//         setIndex(prevIndex => (prevIndex + 1) % words.length);
//         setFade(true);
//       }, 1000);
//     }, 3000);

//     return () => clearInterval(interval);
//   }, []);

//   const handleButtonClick = () => {
//     navigate('/mock-board');
//   };

//   return (
//     <HeroContainer>
//       <HeroTitle>
//         Make your classes <WordContainer>
//           <DynamicWord key={index} fade={fade}>{words[index]}</DynamicWord>
//         </WordContainer>
//       </HeroTitle>
//       <HeroSubtitle>Introducing q+a, the anonymous, real-time lecture question board</HeroSubtitle>
//       <JoinBoard />
//       <HeroButton onClick={handleButtonClick}>See how it works</HeroButton>
//     </HeroContainer>
//   );
// };

// export default Hero;





// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import '../Hero.css';
// import JoinBoard from './JoinBoard';

// const words = ['engaging', 'interactive', 'dynamic'];

// const Hero = () => {
//   const [index, setIndex] = useState(0);
//   const [fade, setFade] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setFade(false);
//       setTimeout(() => {
//         setIndex(prevIndex => (prevIndex + 1) % words.length);
//         setFade(true);
//       }, 1000);
//     }, 3000);

//     return () => clearInterval(interval);
//   }, []);

//   const handleButtonClick = () => {
//     navigate('/mock-board');
//   };

//   return (
//     <section className="hero-container">
//       <h1 className="hero-title">
//         Make your classes <span className="word-container">
//           <span className={`dynamic-word ${fade ? 'fade-in' : 'fade-out'}`}>{words[index]}</span>
//         </span>
//       </h1>
//       <h2 className="hero-subtitle">Introducing q+a, the anonymous, real-time lecture question board</h2>
//       <JoinBoard />
//       <button className="hero-button" onClick={handleButtonClick}>See how it works</button>
//     </section>
//   );
// };

// export default Hero;



import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Hero.css';
import JoinBoard from './JoinBoard';

const words = ['engaging', 'interactive', 'dynamic'];

const Hero = () => {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex(prevIndex => (prevIndex + 1) % words.length);
        setFade(true);
      }, 1000);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = () => {
    navigate('/mock-board');
  };

  return (
    <section className="hero-container">
      <h1 className="hero-title">
        Make your classes
        <span className="word-container">
          <span className={`dynamic-word ${fade ? 'fade-in' : 'fade-out'}`}>{words[index]}</span>
        </span>
      </h1>
      <h2 className="hero-subtitle">Introducing q+a, the anonymous, real-time lecture question board</h2>
      <JoinBoard />
      <button className="hero-button" onClick={handleButtonClick}>Try a demo</button>
    </section>
  );
};

export default Hero;


