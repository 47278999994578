import React from 'react';
import styled from 'styled-components';

const FeaturesContainer = styled.section`
  padding: 50px 5%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const Feature = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const FeatureTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 1.5em;
  color: black
`;

const FeatureDescription = styled.p`
  margin: 0;
  font-size: 1.1em;
  color: black
`;

const Features = () => (
  <FeaturesContainer>
    <Feature>
      <FeatureTitle>What makes this different from other anonymous q+a options?</FeatureTitle>
      <FeatureDescription>Unlike other platforms like Slido and Mentimeter, which offer complete anonymity, we believe this can be counterproductive in the classroom. Our 'peer-to-peer' anonymity ensures participants remain anonymous to each other but not to the room host. This approach ensures a distraction-free session and provides valuable insights, allowing you to identify who needs extra help or who posed that insightful question. All the knowledge, none of the distraction.</FeatureDescription>
    </Feature>
    {/* <Feature>
      <FeatureTitle>Feature 2</FeatureTitle>
      <FeatureDescription>This is another awesome feature.</FeatureDescription>
    </Feature> */}
    {/* <Feature>
      <FeatureTitle>Feature 3</FeatureTitle>
      <FeatureDescription>This is yet another awesome feature.</FeatureDescription>
    </Feature> */}
  </FeaturesContainer>
);

export default Features;

