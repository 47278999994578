// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { db, auth } from '../firebaseConfig';
// import { collection, addDoc, serverTimestamp } from "firebase/firestore";
// import '../MainPage.css'; 
// import SignOutButton from './SignOutButton';

// const MainPage = () => {
//   const navigate = useNavigate();
//   const [joinKey, setJoinKey] = useState("");

//   const createQuestionBoard = async () => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to create a question board");
//       return;
//     }

//     const boardKey = Math.random().toString(36).substr(2, 9);

//     try {
//       await addDoc(collection(db, 'questionBoards'), {
//         key: boardKey,
//         timestamp: serverTimestamp(),
//         hostEmail: user.email,
//       });

//       navigate(`/board/${boardKey}`);
//     } catch (error) {
//       console.error("Error creating question board:", error);
//     }
//   };

//   const joinQuestionBoard = () => {
//     navigate(`/board/${joinKey}`);
//   };


//   return (
//     <div className="MainPage">
//         <div className="main-logo">q+a</div>
//         <div className="sign-out-container">
//     <SignOutButton />
//   </div>
//       <button onClick={createQuestionBoard}>Create Question Board</button>
//       <div className="join-board">
//         <p>Join Question Board</p>
//         <input 
//           type="text" 
//           placeholder="Question board key" 
//           value={joinKey} 
//           onChange={(e) => setJoinKey(e.target.value)} 
//         />
//         <button onClick={joinQuestionBoard}>Join</button>
//       </div>
//     </div>
//   );
// };

// export default MainPage;


// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { db, auth } from '../firebaseConfig';
// import { 
//   collection, 
//   addDoc, 
//   getDocs, 
//   query, 
//   where, 
//   serverTimestamp,
//   updateDoc,
//   doc,
//   arrayUnion
// } from "firebase/firestore";
// import '../MainPage.css';
// import SignOutButton from './SignOutButton';

// const MainPage = () => {
//   const navigate = useNavigate();
//   const [joinKey, setJoinKey] = useState("");
//   const [userBoards, setUserBoards] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [showBoards, setShowBoards] = useState(false);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         fetchUserBoards(user);
//       } else {
//         setLoading(false);
//         setUserBoards([]);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const fetchUserBoards = async (user) => {
//     try {
//       const boardKeys = new Set();

//       // Fetch boards where user asked questions
//       const questionsRef = collection(db, 'questions');
//       const askedQuestionsQuery = query(questionsRef, where('email', '==', user.email));
//       const askedQuestionsSnapshot = await getDocs(askedQuestionsQuery);
      
//       askedQuestionsSnapshot.forEach((doc) => {
//         boardKeys.add(doc.data().boardKey);
//       });

//       // Fetch boards where user upvoted questions
//       const upvotedQuestionsQuery = query(questionsRef, where('upvotedBy', 'array-contains', user.uid));
//       const upvotedQuestionsSnapshot = await getDocs(upvotedQuestionsQuery);
      
//       upvotedQuestionsSnapshot.forEach((doc) => {
//         boardKeys.add(doc.data().boardKey);
//       });

//       // Fetch boards the user has joined (you might need to add a 'participants' field to your questionBoards collection)
//       const boardsRef = collection(db, 'questionBoards');
//       const joinedBoardsQuery = query(boardsRef, where('participants', 'array-contains', user.uid));
//       const joinedBoardsSnapshot = await getDocs(joinedBoardsQuery);
      
//       joinedBoardsSnapshot.forEach((doc) => {
//         boardKeys.add(doc.data().key);
//       });

//       // Fetch details of all collected board keys
//       const boardsData = await Promise.all(
//         Array.from(boardKeys).map(async (boardKey) => {
//           const boardQuery = query(boardsRef, where('key', '==', boardKey));
//           const boardSnapshot = await getDocs(boardQuery);
//           return boardSnapshot.docs[0]?.data();
//         })
//       );

//       setUserBoards(boardsData.filter(Boolean));
//     } catch (error) {
//       console.error("Error fetching user boards:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   const createQuestionBoard = async () => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to create a question board");
//       return;
//     }

//     const boardKey = Math.random().toString(36).substr(2, 9);

//     try {
//       // Create the question board
//       await addDoc(collection(db, 'questionBoards'), {
//         key: boardKey,
//         timestamp: serverTimestamp(),
//         hostEmail: user.email,
//       });

//       navigate(`/board/${boardKey}`);
//     } catch (error) {
//       console.error("Error creating question board:", error);
//     }
//   };

//   const joinQuestionBoard = async () => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to join a question board");
//       return;
//     }
  
//     // Check if the board exists
//     const boardsRef = collection(db, 'questionBoards');
//     const q = query(boardsRef, where('key', '==', joinKey));
//     const querySnapshot = await getDocs(q);
  
//     if (querySnapshot.empty) {
//       alert("No board found with this key");
//       return;
//     }
  
//     // Add user to participants
//     const boardDoc = querySnapshot.docs[0];
//     await updateDoc(doc(db, 'questionBoards', boardDoc.id), {
//       participants: arrayUnion(user.uid)
//     });
  
//     navigate(`/board/${joinKey}`);
//   };

//   const goToBoard = (boardKey) => {
//     navigate(`/board/${boardKey}`);
//   };

//   const toggleBoardsList = () => {
//     setShowBoards(!showBoards);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="MainPage">
//       <div className="main-logo">q+a</div>
//       <div className="sign-out-container">
//         <SignOutButton />
//       </div>
//       <button className="your-boards-button" onClick={toggleBoardsList}>
//         Your Boards
//       </button>
//       <div className={`boards-list-container ${showBoards ? 'open' : ''}`}>
//         <button className="close-boards-list" onClick={toggleBoardsList}>&times;</button>
//         <h2>Your Boards</h2>
//         {userBoards.length === 0 ? (
//           <p>You haven't joined any boards yet.</p>
//         ) : (
//           <ul className="boards-list">
//             {userBoards.map((board, index) => (
//               <li key={index} onClick={() => goToBoard(board.key)}>
//                 Board: {board.key} {board.hostEmail === auth.currentUser?.email ? '(Host)' : ''}
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//       <div className="button-container">
//         <button onClick={createQuestionBoard}>Create Question Board</button>
//       </div>
//       <div className="join-board">
//         <input
//           type="text"
//           placeholder="Question board key"
//           value={joinKey}
//           onChange={(e) => setJoinKey(e.target.value)}
//         />
//         <button onClick={joinQuestionBoard}>Join Board</button>
//       </div>
//     </div>
//   );
// };

// export default MainPage;



// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { db, auth } from '../firebaseConfig';
// import { 
//   collection, 
//   addDoc, 
//   getDocs, 
//   query, 
//   where, 
//   serverTimestamp,
//   updateDoc,
//   doc,
//   arrayUnion
// } from "firebase/firestore";
// import '../MainPage.css';
// import SignOutButton from './SignOutButton';
// import { initializeUserDocument } from '../userUtils'

// const MainPage = () => {
//   const navigate = useNavigate();
//   const [joinKey, setJoinKey] = useState("");
//   const [userBoards, setUserBoards] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [showBoards, setShowBoards] = useState(false);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         initializeUserDocument(user.uid); 
//         fetchUserBoards(user);
//       } else {
//         setLoading(false);
//         setUserBoards([]);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const fetchUserBoards = async (user) => {
//     try {
//       const boardKeys = new Set();

//       // Fetch boards where user asked questions
//       const questionsRef = collection(db, 'questions');
//       const userQuestionsQuery = query(questionsRef, where('email', '==', user.email));
//       const userQuestionsSnapshot = await getDocs(userQuestionsQuery);
      
//       userQuestionsSnapshot.forEach((doc) => {
//         boardKeys.add(doc.data().boardKey);
//       });

//       // Fetch boards where user is a participant
//       const boardsRef = collection(db, 'questionBoards');
//       const participantBoardsQuery = query(boardsRef, where('participants', 'array-contains', user.uid));
//       const participantBoardsSnapshot = await getDocs(participantBoardsQuery);
      
//       participantBoardsSnapshot.forEach((doc) => {
//         boardKeys.add(doc.id);
//       });

//       // Fetch details of all collected board keys
//       const boardsData = await Promise.all(
//         Array.from(boardKeys).map(async (boardKey) => {
//           const boardQuery = query(boardsRef, where('key', '==', boardKey));
//           const boardSnapshot = await getDocs(boardQuery);
//           if (!boardSnapshot.empty) {
//             const boardDoc = boardSnapshot.docs[0];
//             return {
//               ...boardDoc.data(),
//               id: boardDoc.id
//             };
//           }
//           return null;
//         })
//       );

//       setUserBoards(boardsData.filter(Boolean));
//     } catch (error) {
//       console.error("Error fetching user boards:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const goToBoard = (boardKey) => {
//     navigate(`/board/${boardKey}`);
//   };

//   const createQuestionBoard = async () => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to create a question board");
//       return;
//     }

//     const boardKey = Math.random().toString(36).substr(2, 9);

//     try {
//       // Create the question board
//       await addDoc(collection(db, 'questionBoards'), {
//         key: boardKey,
//         timestamp: serverTimestamp(),
//         hostEmail: user.email,
//         participants: [user.uid]
//       });

//       navigate(`/board/${boardKey}`);
//     } catch (error) {
//       console.error("Error creating question board:", error);
//     }
//   };

//   const joinQuestionBoard = async () => {
//     const user = auth.currentUser;
//     if (!user) {
//       alert("You must be signed in to join a question board");
//       return;
//     }

//     // Check if the board exists
//     const boardsRef = collection(db, 'questionBoards');
//     const q = query(boardsRef, where('key', '==', joinKey));
//     const querySnapshot = await getDocs(q);

//     if (querySnapshot.empty) {
//       alert("No board found with this key");
//       return;
//     }

//     // Add user to participants
//     const boardDoc = querySnapshot.docs[0];
//     await updateDoc(doc(db, 'questionBoards', boardDoc.id), {
//       participants: arrayUnion(user.uid)
//     });

//     navigate(`/board/${joinKey}`);
//   };

//   const toggleBoardsList = () => {
//     setShowBoards(!showBoards);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="MainPage">
//       <div className="main-logo">q+a</div>
//       <div className="sign-out-container">
//         <SignOutButton />
//       </div>
//       <button className="your-boards-button" onClick={toggleBoardsList}>
//         Your Boards
//       </button>
//       <div className={`boards-list-container ${showBoards ? 'open' : ''}`}>
//         <button className="close-boards-list" onClick={toggleBoardsList}>&times;</button>
//         <h2>Your Boards</h2>
//         {userBoards.length === 0 ? (
//           <p>You haven't joined any boards yet.</p>
//         ) : (
//           <ul className="boards-list">
//             {userBoards.map((board) => (
//               <li key={board.id} onClick={() => goToBoard(board.key)}>
//                 Board: {board.key} {board.hostEmail === auth.currentUser?.email ? '(Host)' : ''}
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//       <div className="button-container">
//         <button onClick={createQuestionBoard}>Create Question Board</button>
//       </div>
//       <div className="join-board">
//         <input
//           type="text"
//           placeholder="Question board key"
//           value={joinKey}
//           onChange={(e) => setJoinKey(e.target.value)}
//         />
//         <button onClick={joinQuestionBoard}>Join Board</button>
//       </div>
//     </div>
//   );
// };

// export default MainPage;
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from '../firebaseConfig';
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where, 
  serverTimestamp,
  updateDoc,
  doc,
  arrayUnion,
  getDoc
} from "firebase/firestore";
import '../MainPage.css';
import SignOutButton from './SignOutButton';
import { initializeUserDocument } from '../userUtils'

const MainPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [joinKey, setJoinKey] = useState("");
  const [userBoards, setUserBoards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showBoards, setShowBoards] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        initializeUserDocument(user.uid); 
        fetchUserBoards(user);
      } else {
        setLoading(false);
        setUserBoards([]);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchUserBoards(user);
    }
    return () => {
      // This will run when the component unmounts or before the effect runs again
      setUserBoards([]); // Reset the boards when leaving the page
    };
  }, [location]);

  const fetchUserBoards = useCallback (async (user) => {
    try {
      const boardKeys = new Set();

      // Fetch boards where user asked questions
      const questionsRef = collection(db, 'questions');
      const userQuestionsQuery = query(questionsRef, where('email', '==', user.email));
      const userQuestionsSnapshot = await getDocs(userQuestionsQuery);
      
      userQuestionsSnapshot.forEach((doc) => {
        boardKeys.add(doc.data().boardKey);
      });

      // Fetch boards where user is a participant
      const boardsRef = collection(db, 'questionBoards');
      const participantBoardsQuery = query(boardsRef, where('participants', 'array-contains', user.uid));
      const participantBoardsSnapshot = await getDocs(participantBoardsQuery);
      
      participantBoardsSnapshot.forEach((doc) => {
        boardKeys.add(doc.id);
      });

      // Fetch user's last viewed timestamps once
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const lastViewedBoards = userDoc.exists() ? userDoc.data().lastViewedBoards || {} : {};
  
      // Use this lastViewedBoards for all board checks
      const boardsData = await Promise.all(
        Array.from(boardKeys).map(async (boardKey) => {
          const boardQuery = query(boardsRef, where('key', '==', boardKey));
          const boardSnapshot = await getDocs(boardQuery);
          if (!boardSnapshot.empty) {
            const boardDoc = boardSnapshot.docs[0];
            const boardData = boardDoc.data();
            return {
              ...boardData,
              id: boardDoc.id,
              displayName: boardData.boardName || boardData.key, // Use boardName if available, otherwise use key
              hasNewAnswers: boardData.lastAnswerTimestamp && 
                             boardData.lastAnswerTimestamp.toMillis() > (lastViewedBoards[boardKey]?.toMillis() || 0)
            };
          }
          return null;
        })
      );

      // Sort boards: boards with new answers first, then by timestamp
      const sortedBoards = boardsData
        .filter(Boolean)
        .sort((a, b) => {
          if (a.hasNewAnswers && !b.hasNewAnswers) return -1;
          if (!a.hasNewAnswers && b.hasNewAnswers) return 1;
          return b.timestamp.toMillis() - a.timestamp.toMillis();
        });

      setUserBoards(sortedBoards);
    } catch (error) {
      console.error("Error fetching user boards:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        initializeUserDocument(user.uid); 
        fetchUserBoards(user);
      } else {
        setLoading(false);
        setUserBoards([]);
      }
    });
  
    return () => unsubscribe();
  }, [fetchUserBoards]);  // Add fetchUserBoards to the dependency array
  
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchUserBoards(user);
    }
    return () => {
      setUserBoards([]);
    };
  }, [location, fetchUserBoards]);


  const goToBoard = async (boardKey) => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const now = serverTimestamp();
      
      // Update Firestore
      await updateDoc(userRef, {
        [`lastViewedBoards.${boardKey}`]: now
      });
  
      // Update local state
      setUserBoards(prevBoards => {
        const updatedBoards = prevBoards.map(board => 
          board.key === boardKey ? { ...board, hasNewAnswers: false } : board
        );
        return updatedBoards;
      });
  
      // Navigate after updates
      navigate(`/board/${boardKey}`);
    } else {
      navigate(`/board/${boardKey}`);
    }
  };

  const createQuestionBoard = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert("You must be signed in to create a question board");
      return;
    }

    const boardKey = Math.random().toString(36).substr(2, 9);

    try {
      // Create the question board
      await addDoc(collection(db, 'questionBoards'), {
        key: boardKey,
        timestamp: serverTimestamp(),
        hostEmail: user.email,
        participants: [user.uid],
        lastAnswerTimestamp: null
      });

      // Update user's lastViewedBoards
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        [`lastViewedBoards.${boardKey}`]: serverTimestamp()
      });

      navigate(`/board/${boardKey}`);
    } catch (error) {
      console.error("Error creating question board:", error);
    }
  };

  const joinQuestionBoard = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert("You must be signed in to join a question board");
      return;
    }

    // Check if the board exists
    const boardsRef = collection(db, 'questionBoards');
    const q = query(boardsRef, where('key', '==', joinKey));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      alert("No board found with this key");
      return;
    }

    // Add user to participants
    const boardDoc = querySnapshot.docs[0];
    await updateDoc(doc(db, 'questionBoards', boardDoc.id), {
      participants: arrayUnion(user.uid)
    });

    // Update user's lastViewedBoards
    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, {
      [`lastViewedBoards.${joinKey}`]: serverTimestamp()
    });

    navigate(`/board/${joinKey}`);
  };

  const toggleBoardsList = () => {
    setShowBoards(!showBoards);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="MainPage">
      <div className="main-logo">q+a</div>
      <div className="sign-out-container">
        <SignOutButton />
      </div>
      <button className="your-boards-button" onClick={toggleBoardsList}>
        Your Boards
      </button>
      <div className={`boards-list-container ${showBoards ? 'open' : ''}`}>
        <button className="close-boards-list" onClick={toggleBoardsList}>&times;</button>
        <h2>Your Boards</h2>
        {userBoards.length === 0 ? (
          <p>You haven't joined any boards yet.</p>
        ) : (
          <ul className="boards-list">
            {userBoards.map((board) => (
              <li key={board.id} onClick={() => goToBoard(board.key)}>
                {board.displayName} {board.hostEmail === auth.currentUser?.email ? '(Host)' : ''}
                {board.hasNewAnswers && <span className="new-answer-notification">new answer</span>}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="button-container">
        <button onClick={createQuestionBoard}>Create Question Board</button>
      </div>
      <div className="join-board">
        <input
          type="text"
          placeholder="Question board key"
          value={joinKey}
          onChange={(e) => setJoinKey(e.target.value)}
        />
        </div>
        <button onClick={joinQuestionBoard}>Join Board</button>
      
    </div>
  );
};

export default MainPage;