

import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './components/MainPage';
import QuestionBoard from './components/QuestionBoard';
import SigninPage from './components/SigninPage';
import SignupPage from './components/SignupPage';
import MockQuestionBoard from './components/MockQuestionBoard';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import { auth } from './firebaseConfig';
import { signOut } from 'firebase/auth';
// import { AuthProvider } from './components/AuthContextProvider';

const AppContainer = styled.div`
  background: linear-gradient( 360deg, #212529 , #212529 );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
`;


const LandingPage = () => (
  <Content>
    <Header />
    <Hero />
    <Features />
    <Footer />
  </Content>
);


const App = () => {
  const [user, setUser] = useState()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user)
    })
  })

  // useEffect(() => {
  //   const signOutUser = async () => {
  //     await signOut(auth);
  //   };

  //   signOutUser();
  // }, []);
  return (
  // <AuthProvider>
    <Router>
      <AppContainer>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<SigninPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/board/:boardKey" element={<QuestionBoard />} />
          <Route path="/mock-board" element={<MockQuestionBoard />} />
        </Routes>
      </AppContainer>
    </Router>
  // </AuthProvider>
  );
};

export default App;



