import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 20px;
  background: transparent;
  color: #fff;
  text-align: center;
  font-size: 1em;
`;

const Footer = () => (
  <FooterContainer>
    &copy; {new Date().getFullYear()} A project by Nathan Igbokwe
  </FooterContainer>
);

export default Footer;
